import React from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography,Link
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start

import ProjectDetail from "../../../components/src/ProjectDetail";
import JobProfileController,{ Props } from "./ProjectDetailPHController";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const theme = createTheme({
    palette: {
        primary: {
            main: "#f5c42c",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class UserMyJobDetail extends JobProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    ProjectDetailHeading() {
      let status = this.state.allProjectDetail?.attributes?.status;
      let headingText;
    
      if (status === "ongoing") {
        headingText = "Ongoing Projects";
      } else if (status === "upcoming") {
        headingText = "Upcoming Projects";
      } else {
        headingText = "Projects";
      }
    
      return headingText;
    }
    
    renderMainContentMyJobDetail(sideBarStatus: boolean) {
        return <Grid style={webStyle.contentSetupMyjob}>
              <Loader loading={this.state.isLoading}></Loader>
            <Header
             
                sideBarStatus={this.state.isSideBarOpen}
                dropDownStatus={this.state.isDropdownOpen}
                handleDropdown={this.handleDropdown}
                handleSideBar={this.handleSideBar}  
                navigate={this.props.navigation.navigate}
            />
            <Grid style={webStyle.mainContentMyjob as React.CSSProperties}>
                <Box sx={webStyle.blockInfoMyjob}>
                <ArrowBackIcon data-test-id="backArrow" onClick={() => this.handleNavigation("Projects")} style={{
                                        background: "white",cursor:"pointer", border: "1px solid grey", borderRadius: "50%", marginRight: "10px", padding: "2px"
                                    }} />
                  <Box>              
                <Typography variant="h3" style={webStyle.activeBlockMainTitlemyjob as TypographyProps}>Project Details</Typography>
                <Typography variant="h6" style={webStyle.blockE2EPathmyjob as TypographyProps}>
                <span style={webStyle.highlightedTitleextmyjob}>
                  <Link data-test-id="homeLinkmyjob" color="inherit" onClick={() => this.handleNavigation("Projects")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTitleextmyjob}>
                  <Link data-test-id="myjobdetail" color="inherit" onClick={() => this.handleNavigation("Projects")}>
                    {" "}/ {this.ProjectDetailHeading()}
                  </Link>
                </span>
                <span title={this.state.allProjectDetail && this.state.allProjectDetail.attributes && this.state.allProjectDetail.attributes.project_name} style={webStyle.projectName}>{" "}/{" "} {this.state.allProjectDetail && this.state.allProjectDetail.attributes && this.state.allProjectDetail.attributes.project_name}</span>
              </Typography>
              </Box>    
                </Box>
              
               <ProjectDetail EditProject={this.EditProject}  navigateToallinvoiceRequest={this.navigateToallinvoiceRequest} data={this.state.allProjectDetail} deleteProjectPH={this.deleteProjectPH} jobRoleNavigation={this.jobRoleNavigation} handleArrowRightClick={this.handleArrowRightClick} handleArrowRightClickDepartment={this.handleArrowRightClickDepartment}/>
            </Grid>
            <Footer />
        </Grid>
         
    }
    
    render() {
        return ( 
            <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarMyjob}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"Projects"}
                                    handleSideBar={this.handleSideBar}
                                    navigate={this.props.navigation.navigate}
                                    />
                            </Grid>
                            <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }}>{this.renderMainContentMyJobDetail(this.state.isSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderMainContentMyJobDetail(this.state.isSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
          

            </ThemeProvider>
        );  }
}

// Customizable Area Start
const webStyle = {
    contentSetupMyjob: {
        borderLeft: "1px solid #E3EAF4",
    },
    highlightedTitleextmyjob: {
        fontFamily: "Urbanist",
        color: "#F5C42C",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: 600,
      },
      activeBlockMainTitlemyjob: {
        lineHeight: "34px",
        fontSize: "28px",
        color: "#17181D",
        fontWeight: "700",
        letterSpacing: "1px",
      },
      blockE2EPathmyjob: {
        fontSize: "14px",
        letterSpacing: "1px",
        fontWeight: 500,
        color: "#6D89AF",
        lineHeight: "17px",
        paddingTop: "8px",
      },
      projectName: {
        overflow:'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
        display: 'inline-block',
        maxWidth: '500px'
      },
    containerMyjob: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important',
    },
    sideBarMyjob: {
        minHeight: "100vh",
        maxWidth: "22.25%",
    },
    blockInfoMyjob: {
        paddingBottom:'24px',
        display:'flex',
        alignItems:'center'

    },
    blockPathMyjob: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "24px",
        fontWeight: 500
      },
      highlightedTextMyjob: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
    activeBlockMyjob: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px',   
      },
     
      mainContentMyjob: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        paddingLeft: "24px",
        paddingTop:'24px',
        paddingRight:'24px',
        paddingBottom:'24px'
      },
};
// Customizable Area End