import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../framework/src/BlockComponent";


// Customizable Area Start
// Customizable Area End

//export const configJSON = require("./config");
export interface Props {
  //navigation: any;
  // Customizable Area Start
  sideBarStatus: any;
  handleSideBar: any;
  handleDropdown: any;
  dropDownStatus: any
  navigate: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studioBookTypeTask: string;
  typeofinvoiceTask: any;
  userEmail: string;
  userName: string;
  userProfile: string
  accountType: string;
  open: boolean;
  txtSavedValue: string;
  notificationdata: any;
  modelType: string;
  searchText: string;
  searchData: any;
  notification: { type: string, open: boolean, message: string, route?: string };
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  notificationcallid: string = "";
  globalSearchCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      studioBookTypeTask: "per_day",
      userEmail: "",
      userName: "",
      userProfile: "",
      accountType: "",
      open: false,
      txtSavedValue: '',
      typeofinvoiceTask: getStorageData('typeofraiseinvoice'),
      notificationdata: [],
      modelType: "",
      searchText: "",
      searchData: [],
      notification: { open: false, type: "", message: "" },
      isLoading: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let msgvaluejob = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From:" + this.state.txtSavedValue + "To:" + msgvaluejob
      );

      this.setState({ txtSavedValue: msgvaluejob });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestvaluejob = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const responseValuejob = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if (apiRequestvaluejob === this.notificationcallid) {
        this.setState({ notificationdata: responseValuejob.data, isLoading: false });
      } else if (apiRequestvaluejob === this.globalSearchCallId) {
        if (responseValuejob.data) {
          this.setState({ searchData: responseValuejob.data });
        } else if (responseValuejob.errors && responseValuejob.errors[0].token) {
          this.setState({ notification: { type: "error", open: true, message: responseValuejob.errors[0].token } });
        } else if (responseValuejob.message) {
          this.setState({ notification: { type: "warning", open: true, message: responseValuejob.message } });
        }
        this.setState({ isLoading: false });
      }
    }


  }

  // Customizable Area Start

  async componentDidMount() {
    this.Notificationmessage();
    const currrentUserEmail = await getStorageData('email');
    this.setState({ userEmail: currrentUserEmail })
    const currentUserName = await getStorageData('full_name');
    this.setState({ userName: currentUserName });
    const currentImage = await getStorageData('image');
    this.setState({ userProfile: currentImage });
    const accountType = await getStorageData('account_type');
    this.setState({ accountType: accountType });
  };

  getRegex = () => {
    return new RegExp(this.state.searchText, "gi");
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  Logoutproject = async () => {
    localStorage.clear()
    this.props.navigate("LandingPage")
  };

  handleOpen = () => {
    this.setState({ open: true })
    this.Notificationmessage()
  }

  handleSettingsNavigation = () => {
    if (this.state.accountType === "admin")
      this.props.navigate("UserSettings")
    else if (this.state.accountType === "vendor_account")
      this.props.navigate("VendorProfileSettings")
    else if(this.state.accountType === "production_house_account")
      this.props.navigate("ProductionHouseProfileSettings")
  }
  // Notificationmessagevalue = async () => {


  //     const authenticationToken = await getStorageData('token', false)
  //       const header = {
  //         "Content-Type": "application/json",
  //         "token": authenticationToken
  //       };


  //       const requestMessage = new Message(
  //         getName(MessageEnum.RestAPIRequestMessage)
  //       );

  //       this.notificationcallid = requestMessage.messageId;
  //       requestMessage.addData(
  //         getName(MessageEnum.RestAPIRequestHeaderMessage),
  //         JSON.stringify(header)
  //       )
  //         requestMessage.addData(
  //           getName(MessageEnum.RestAPIResponceEndPointMessage),
  //           "bx_block_notifications/notifications"

  //         );

  //       requestMessage.addData(
  //         getName(MessageEnum.RestAPIRequestMethodMessage),
  //         "GET"
  //       );
  //       runEngine.sendMessage(requestMessage.id, requestMessage);
  //       return requestMessage.messageId;

  //   };

  handleProfileNavigation = () => {
    if (this.state.accountType === "admin")
      this.props.navigate("UserProfileBasicBlock")
    else if (this.state.accountType === "vendor_account")
      this.props.navigate("VendorManageProfile")
    else if(this.state.accountType === "production_house_account")
      this.props.navigate("ProductionHouseManageProfile")
  }

  globalSearchAPICall = async (searchValue: string) => {
    const token = await getStorageData('token', false);
    const globalSearchHeader = {
      "Content-Type": "application/json",
      "token": token
    };
    const globalSearchRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.globalSearchCallId = globalSearchRequestMessage.messageId;
    globalSearchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(globalSearchHeader)
    )
    globalSearchRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_elasticsearch/global_search?search=" + searchValue
    );
    globalSearchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(globalSearchRequestMessage.id, globalSearchRequestMessage);
    return globalSearchRequestMessage.messageId;
  };

  Notificationmessage = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": "application/json",
      "token": authenticationToken
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.notificationcallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_notifications/notifications"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleGlobalSearch = (event: any) => {
    const newValue = event.target.value;
    setTimeout(() => {
      if (newValue) {
        this.setState({ searchText: newValue });
        this.globalSearchAPICall(newValue);
      } else {
        this.setState({ searchText: newValue, searchData: [] });
      }
    }, 1000);
  }


  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  navigateToVendorProfile = (inviteVendorUserId:any) => {
    setStorageData("userInviteVendor", inviteVendorUserId);  
    this.props.navigate("ManageProfileInviteVendor");
}
  // Customizable Area End
}
